body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.animated-gradient {
  color: #fff;
  background: linear-gradient(-45deg, #c42da8, #6501de, #ff5770);
  /* background: linear-gradient(-45deg, #23A6D5, #23D5AB); */
  /* background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB); */
  background-size: 200% 200%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}
@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}
@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}
@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

.Typist .Cursor {
  display: inline-block; }
  .Typist .Cursor--blinking {
    opacity: 1;
    animation: blink 1s linear infinite; }
@keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }


/* @media only screen and (min-width: 800px) { */
.home-bg {
  background-image: url('/public/img/home-bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
}
/* } */

/* .epic-button {
  background-color: white !important;
}
.epic-button::after {
  position: absolute;
  content: "";
  top: 8px;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  transform: scale(0.9) translateZ(0);
  filter: blur(10px);
  background: linear-gradient(
    to left,
    #ff5770,
    #e4428d,
    #c42da8,
    #9e16c3,
    #6501de,
    #9e16c3,
    #c42da8,
    #e4428d,
    #ff5770
  );
  background-size: 200% 200%;
  animation: animateGlow 1.25s linear infinite;
}
@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
} */


.pricing-paper-hover-effect {
  border: 1px solid #ccc;
}
.pricing-paper-hover-effect:hover {
  cursor: pointer;
  box-shadow: 0 0 5px 5px white;
}

/*
On smaller screens, content right of drawer will go offscreen instead of being compressed.
On larger screens compression is fine.
*/
@media only screen and (max-width: 600px) {
  .mobile-have-min-width {
    min-width: calc(100vw - 55px);
  }
}


/* all links should have pointer */
a {
  cursor: pointer;
}

.menu-item-link {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.footer-item-link a {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
